<template>
  <div class="content">
    <!--        顶部固定tab切换-->
    <div v-if="isTopTabShow" class="topTab">
      <ul class="tab-tilte">
        <li
          v-for="(item,index) in listdata"
          ref="btnClick"
          :key="index"
          :class="{active:cur==index}"
          @click="SelNumber(item,index)"
        >
          <div>
            <p class="tab">{{ item.goodsInfo.cardName }}</p>
            <p class="tab1">¥{{ item.goodsInfo.discountPrice }}/月起</p>
          </div>
        </li>
      </ul>
    </div>
    <img class="topImg" :src="listdata[0].goodsInfo.bannerUrl" alt="">
    <div class="cardContent">
      <ul ref="MiddleTab" class="tab-tilte">
        <li
          v-for="(item,index) in listdata"
          ref="btnClick"
          :key="index"
          :class="{active:cur==index}"
          @click="SelNumber(item,index)"
        >
          <div v-if="index === 0">
            <p class="tab">{{ item.goodsInfo.cardName }}</p>
            <p class="tab1">¥{{ item.goodsInfo.discountPrice }}/月起</p>
          </div>
          <!--                    discountPrice折后价originalPrice原价-->
          <div v-if="index === 1">
            <p class="tab">{{ item.goodsInfo.cardName }}</p>
            <div class="tab2">
              <p class="tab1" style="margin-left: 0px;">¥{{ item.goodsInfo.discountPrice }}/月起</p>
              <p class="originalPrice"><span>¥{{ item.goodsInfo.originalPrice }}/月起</span></p>
            </div>
          </div>
        </li>
      </ul>
      <div class="tab-content">
        <div class="defild" @click="kingdetail">查看详情
          <van-icon name="arrow" />
        </div>
        <div class="tesv">
          <img v-for="(items,indexs) in listdata[cur].images" :key="indexs" :src="items" alt="">
        </div>
      </div>
    </div>
    <div class="botBtn" @click="goInfoFill">立即申请</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Toast).use(Icon)
import { getCardInfo } from '@/services/comeOn'
import { wxConfigInit, showShare } from '@/utils/wx-api'

export default {
  name: 'Index',
  data() {
    return {
      listdata: [],
      cur: 0, // 默认选中第一个tab
      isTopTabShow: false,
      goodsId: '', // 选中的商品id
      goodName: ''// 选中的商品名称
    }
  },
  computed: {
    objectActive: function() {
      return { 'active1': this.cur === 0, 'active2': this.cur === 1 }
    }
  },
  watch: {// 监听值改变后调用

  },
  created() {
    this.getCardInfo()
    window.addEventListener('scroll', this.handleScroll)
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => { this.onBridgeReady() }, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', () => { this.onBridgeReady() })
        document.attachEvent('onWeixinJSBridgeReady', () => { this.onBridgeReady() })
      }
    } else {
      this.onBridgeReady()
    }
    this.SetUpShare()// 分享
  },
  mounted() {
    showShare()// 启用分享
  },
  destroyed() { // 离开该页面需要移除这个监听的事件
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    SetUpShare() {
      const shareInfo = {
        title: '办理大王卡，免费流量30G起，尽享悦淘首充免额及金卡会员超多礼遇！', // 分享标题
        desc: '我在悦淘有发现，绝对适合你，速来围观～', // 分享描述
        link: 'https://web.yuetao.vip/KingCardIndex?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-24/19/yuelvhuisbJ5D6Guf31611488251.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    },
    onBridgeReady() {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.call('hideOptionMenu')
    },
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // const offsetTop = this.$refs.MiddleTab.offsetTop
      this.isTopTabShow = scrollTop === 297 || scrollTop > 297
    },
    // 进详情
    kingdetail() {
      this.$router.push({ path: '/kingdetail' })
    },
    // get卡种信息
    getCardInfo() {
      getCardInfo().then(res => {
        if (Number(res.code) === 200) {
          this.listdata = res.data
          this.SelNumber(this.listdata[0], 0)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 立即申请信息填写
    goInfoFill() {
      this.$router.push({
        path: '/infoFill',
        query: {
          cur: this.cur,
          goodsId: this.goodsId,
          goodName: this.goodName
        }
      })
    },
    //    选择卡种类型
    SelNumber(item, index) {
      this.cur = index
      this.goodsId = item.goodsInfo.goodsId
      this.goodName = item.goodsInfo.goodName
    },
    //    查看更多资费详情
    Consultation() {

    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app, .content {
  width: 100%;
  /*height: 100%;*/
  margin: 0;
  padding: 0;
}

.content {
  position: absolute;
  background: #03014C;

  .topTab {
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    z-index: 9;

    .tab-tilte {
      position: absolute;
      width: 100%;
      height: 55px;
    }

    .tab-tilte {
      li {
        float: left;
        width: 50%;
        padding: 10px 0;
        text-align: center;
        background-color: #424242;
        color: #999999;
        cursor: pointer;

        .tab {
          font-size: 19px;
          font-weight: 700;
        }

        .tab1 {
          font-size: 12px;
          font-weight: 500;
        }

        .tab2 {
          display: flex;
          font-size: 12px;
          text-align: center;
          margin: 0 auto;
          width: 170px;

          p:nth-child(1) {
            width: 85px;
          }

          p:nth-child(2) {
            width: 85px;
          }

          .originalPrice {
            width: 75px;

            span {
              display: inline-block;
              text-decoration: line-through;
              font-size: 12px;
              transform: scale(0.75);
            }
          }
        }

        .FormsWrap {

        }
      }
    }

    /* 点击对应的标题添加对应的背景颜色 */

    .tab-tilte .active {
      background-color: #E86C3F;
      color: #fff;
    }

    .tab-tilte .active1 {
      background-color: #E86C3F;
      color: #fff;
      border-radius: 28px 0 0 28px;
    }

    .tab-tilte .active2 {
      background-color: #E86C3F;
      color: #fff;
      border-radius: 0 28px 28px 0;
    }
  }

  .topImg {
    width: 100%;
  }

  .cardContent {
    .tab-tilte {
      position: absolute;
      width: 323px;
      height: 55px;
      left: 50%;
      margin-left: -161.5px;
      border-radius: 28px;
    }

    .tab-tilte {
      li:nth-child(1) {
        border-radius: 28px 0 0 28px;
      }

      li:nth-child(2) {
        border-radius: 0 28px 28px 0;
      }

      li {
        float: left;
        width: 50%;
        padding: 10px 0;
        text-align: center;
        background-color: #424242;
        color: #999999;
        cursor: pointer;

        .tab {
          font-size: 19px;
          font-weight: 700;
        }

        .tab1 {
          font-size: 12px;
          font-weight: 500;
        }

        .tab2 {
          display: flex;
          text-align: center;
          font-size: 12px;
          width: 170px;
          /*margin-left: 5px;*/

          .tab1 {
            width: 85px;
            text-align: right;
          }

          .originalPrice {
            margin-top: -2px;
            width: 85px;
            text-align: left;

            span {
              display: inline-block;
              text-decoration: line-through;
              font-size: 12px;
              transform: scale(0.75);
              margin-top: 2px;
            }
          }
        }

        .FormsWrap {

        }
      }
    }

    /* 点击对应的标题添加对应的背景颜色 */

    .tab-tilte .active {
      background-color: #E86C3F;
      color: #fff;
    }

    .tab-tilte .active1 {
      background-color: #E86C3F;
      color: #fff;
      border-radius: 28px 0 0 28px;
    }

    .tab-tilte .active2 {
      background-color: #E86C3F;
      color: #fff;
      border-radius: 0 28px 28px 0;
    }

    .tab-content {
      position: relative;

      .defild {
        position: absolute;
        top: 130px;
        left: 50%;
        color: #EFE3AC;
        margin-left: -35px;
        font-size: 12px;
        text-align: center;
        opacity: 0;
      }

      .tesv {
        float: left;
        width: 100%;
        line-height: 100px;
        text-align: center;
        margin-top: 60px;
        margin-bottom: 80px;

        img {
          outline-width: 0px;
          vertical-align: top;
        }

        .tablere {
          width: 80%;
          margin: 0 auto 23px;
          border: 1px solid #C2A66C;
          /*margin-bottom: 23px;*/
          table-layout: fixed;
          margin-top: 20px;

          .thStyle {
            max-height: 50px;
            border-right: 0.5px solid #fff;
          }

          th {
            background: -webkit-linear-gradient(top, #BFA56D, #E6CE9C);
            font-size: 13px;
            font-weight: 600;
            color: #1B140B;
            text-align: center;
            padding: 5px;
            max-height: 50px;
          }

          td {
            color: #fff;
            font-size: 13px;
            line-height: 15px;
            border: 0.5px solid #E7E7E7;
            padding: 8px;
            text-align: center;

            img {
              height: 11px;
            }
          }
        }
      }
    }
  }

  .MoreConsultation {
    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    margin-bottom: 100px;

    .Consultation {
      display: inline-block;
      margin-left: 5px;
      text-decoration: underline;
    }
  }

  .botBtn {
    position: fixed;
    bottom: 36px;
    width: 279px;
    height: 42px;
    line-height: 42px;
    background: -webkit-linear-gradient(left, #516CF5, #7BEEA4);
    text-align: center;
    color: #fff;
    font-size: 21px;
    left: 50%;
    margin-left: -139.5px;
    /*margin-bottom: 36px;*/
    border-radius: 21px;
  }
}

/deep/ .van-tabs__nav--card {
  border: none !important;
  border-radius: 28px;
}

/deep/ .active1 .van-tabs__nav--card .van-tab.van-tab--active {
  border-radius: 28px 0 0 28px;
}

/deep/ .active2 .van-tabs__nav--card .van-tab.van-tab--active {
  border-radius: 0 28px 28px 0;
}

/deep/ .van-tabs__nav--card .van-tab {
  border: none;
}
</style>
